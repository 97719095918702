<template>
    <div class="author-index">
        <section class="author-index__main">
            <div class="author-index__card-container">
                <article class="author-index__card">
                    <picture class="author-index__card-picture">
                        <img :src="info.data.author.image.url" :alt="info.data.author.name" />
                    </picture>
                    <div class="author-index__card-info">
                        <div class="card-info">
                            <h1 class="card-info__name">{{ info.data.author.name }}</h1>
                            <h2 class="card-info__position">{{ info.data.author.position }}</h2>
                        </div>
                        <nav class="card-social">
                            <ul class="card-social__list">
                                <li v-for="(data, index) in info.data.author.rrss" :key="index">
                                    <NuxtLink :to="data.url">
                                        <img
                                            width="24px"
                                            height="24px"
                                            :src="
                                                data.name === 'instagram'
                                                    ? $assets.social.instagramAuthor
                                                    : data.name === 'linkedin'
                                                      ? $assets.social.linkedinAuthor
                                                      : data.name === 'twitter'
                                                        ? $assets.social.twitterNewLogoNoCircular
                                                        : ''
                                            "
                                            :alt="data.name"
                                            :title="`Ir a ${data.name}`"
                                        />
                                    </NuxtLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </article>
            </div>

            <div
                class="author-index__content"
                v-if="info.data.author.long_description"
                v-html="info.data.author.long_description"
            ></div>
        </section>
        <section class="author-index__articles">
            <div class="author-index__articles-heading">
                <h2 class="author-index__articles-title">Artículos de {{ info.data.author.name }}</h2>
            </div>
            <ul class="articles-list">
                <li class="articles-list__item" v-for="(data, index) in info.data.last_posts" :key="index">
                    <CardAuthorArticle :info="data" :category="data.category.slug" />
                </li>
            </ul>
        </section>
    </div>
</template>

<script lang="ts" setup>
import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const Route = useRoute()

const { data: responseData, error } = await useAsyncData('author-index-data', () => {
    return $fetch<Api.Responses.Pages.Author>(
        endpoints.pages.author.path.replace('_SLUG_', Route.params.slug as string),
        {
            headers: buildHeaders(),
            method: 'GET',
            baseURL,
        },
    ).catch((e) => e.data)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}
const info = responseData.value

const currentDates = currentDate()

const {
    public: { origin, siteName },
} = useRuntimeConfig()

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
        [':author', info.data.author.name || ''],
    ],
    [
        'Artículos de :author | :site',
        ' Artículos redactados y revisados por :author en :site. Encuentra contenidos relacionados con ahorro y descuentos.',
    ],
)

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss">
.author-index {
    &__main {
        @apply flex flex-col bg-site-background;
    }

    &__card-container {
        @apply bg-white px-[15px] py-[45px] md:mt-[35px] md:rounded lg:mx-0 lg:w-full;
    }

    &__card {
        @apply flex flex-col items-center gap-y-[25px] md:rounded lg:mx-auto lg:max-w-[790px] lg:items-start;
        &-picture {
            @apply size-[98px] overflow-hidden rounded-full lg:size-[140px];
            img {
                @apply size-full object-contain;
            }
        }
        &-info {
            @apply flex flex-col items-center gap-4 lg:w-full lg:flex-row;

            & .card-info {
                @apply flex flex-col items-center lg:items-start;
                &__name {
                    @apply mb-2 text-[28px] font-medium md:text-[32px];
                }
                &__position {
                    @apply text-lg text-site-gray lg:text-[28px];
                }
            }

            & .card-social {
                @apply flex flex-1 justify-end;

                &__list {
                    @apply flex gap-x-3;
                }
            }
        }
    }

    &__content {
        @apply prose max-w-full rounded-lg px-[15px] py-[55px] prose-a:text-site-primary lg:mx-auto lg:max-w-[790px] lg:px-0;
    }

    &__content :where(h2) {
        @apply mt-0;
    }

    &__articles {
        @apply bg-site-background pb-[55px] lg:mx-auto lg:max-w-[790px];

        &-heading {
            @apply py-[25px];
        }
        &-title {
            @apply text-center text-xl lg:text-[28px];
        }

        & .articles-list {
            @apply flex flex-col gap-y-[25px];

            &__item {
                @apply self-center lg:self-start;
            }
        }
    }
}
</style>
